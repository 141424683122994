import React from 'react';
import { Link } from 'react-router-dom';
import Particles from 'react-tsparticles';
import { particlesOptions } from '../../helpers/constants';

function HomeBanner() {
    return (
        <section className='hero bg-dark py-5'>
            <Particles options={particlesOptions} />
            <div className='container py-5 z-index-20 position-relative mt-5'>
                <div className='row align-items-center mt-5'>
                    <div className='col-lg-5'>
                        <h2>White Rose Group NFT Membership</h2>
                        <p className='text-muted'>
							Be among the first who capitalize on this pioneer investment opportunity. This is an investment that will allow fractional ownership of real estate through tokens, which in exchange for the investment, the holder will be provided with an Annual Return on your Investment.
                        </p>
                        <ul className='list-inline'>
                            <li className='list-inline-item'>
                                <Link className='btn btn-gradient-primary' to='/mint'>
                                    Mint NFT
                                </Link>
                            </li>
                            <li className='list-inline-item'>
                                <Link className='btn btn-light' to='/explore'>
                                    Explore
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className='col-lg-6 ms-auto d-none d-lg-block'>
                        <img className='img-fluid mx-auto' src=' images/illu-3.png' alt='..' />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeBanner;
