import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import web3 from './connection/web3';
import Web3Context from './store/web3-context';
import CollectionContext from './store/collection-context';
import MarketplaceContext from './store/marketplace-context';
import NFTCollection from './contracts/NFTCollection.json';
import NFTMarketplace from './contracts/NFTMarketplace.json';

// COMPONENTS
import Header from './components/general/Header';
import Footer from './components/general/Footer';
import ScrollTopButton from './components/general/ScrollTopButton';
import NotFound from './components/general/NotFound';

// PAGES
import Home from './components/Home';
import Contact from './components/Contact';
import CreateItem from './components/CreateItem';
import Explore from './components/Explore';
import Authors from './components/Authors';
import Blog from './components/Blog';
import MyAssets from './components/MyAssets';
import NoMetaMaskAlert from './components/general/NoMetaMaskAlert';
import NoContractAlert from './components/general/NoContractAlert';
import ScrollToTop from './components/general/ScrollToTop';

// Main Style
import './App.css';
import Search from './components/Search';
import ItemSingle from './components/ItemSingle';
import Category from './components/Category';

function App() {
    const [noMetaMask, setNoMetaMask] = useState(false);
    const [noContract, setNoContract] = useState(false);
    const web3Ctx = useContext(Web3Context);
    const collectionCtx = useContext(CollectionContext);
    const marketplaceCtx = useContext(MarketplaceContext);
    const [networkType, setNetworkType] = useState(null);
    const [topSellers, setTopSellers] = useState([]);
    const { addToast } = useToasts();

    // let formatedSellers = [];

    useEffect(() => {
        if (marketplaceCtx.sellers) {
            const sellersAddress = marketplaceCtx.sellers['0'];
            const sellersEth = marketplaceCtx.sellers['1'];

            let values = [];
            for (let i = 0; i < sellersAddress.length; i++) {
                values.push({
                    address: sellersAddress[i],
                    value: parseInt(sellersEth[i]),
                });
            }
            var calcTopSellers = [];
            values.forEach(function (item) {
                var existing = calcTopSellers.filter(function (v, i) {
                    return v.address === item.address;
                });
                if (existing.length) {
                    var existingIndex = calcTopSellers.indexOf(existing[0]);
                    calcTopSellers[existingIndex].value = calcTopSellers[existingIndex].value.concat(item.value);
                } else {
                    if (typeof item.value === 'number') item.value = [item.value];
                    calcTopSellers.push(item);
                }
            });

            setTopSellers(
                calcTopSellers.map((seller) => {
                    return { address: seller.address, value: seller.value.reduce((a, b) => a + b, 0) };
                })
            );
        }
    }, [marketplaceCtx.sellers]);

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <BrowserRouter>
                {noMetaMask && <NoMetaMaskAlert />}
                {!noContract && <Header />}
                {noContract ? <NoContractAlert network={networkType} /> : null}
                <ScrollToTop>
                    <Switch>
                        <Route path='/' exact>
                            <Home topSellers={topSellers} />
                            <ScrollTopButton />
                        </Route>
                        <Route path='/contact'>
                            <Contact />
                        </Route>
                        <Route path='/mint'>
                            <CreateItem />
                        </Route>
                        <Route path='/explore'>
                            <Explore />
                        </Route>
                        <Route path='/assets/:id'>
                            <ItemSingle />
                        </Route>
                        <Route path='/categories/:category'>
                            <Category />
                        </Route>
                        <Route path='/my-assets'>
                            <MyAssets />
                        </Route>
                        <Route path='/search'>
                            <Search />
                        </Route>
                        <Route path='/authors'>
                            <Authors sellers={topSellers} />
                        </Route>
                        <Route path='/connect'>
							<NoMetaMaskAlert />
                        </Route>
                        <Route path='/homepage' component={() => { 
                            window.location.href = 'https://whiterosegroup.io'; 
                            return null;
                        }}/>
                        <Route>
                            <NotFound />
                        </Route>
                    </Switch>
                </ScrollToTop>
                <Footer />
            </BrowserRouter>
        </div>
    );
}

export default App;
